import React from 'react';
import FooterTop from './FooterTop';
import CopyRight from './Copyright';
// import FooterContact from './FooterContact';
const Footer = () => {
    return (
        <>
            {/* <FooterContact /> */}
            <FooterTop />
            <CopyRight />
        </>
    );
};

export default Footer;
